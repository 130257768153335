import { ImageFieldImage } from '@prismicio/types';
import { useCallback, useMemo } from 'react';
import { UseInfiniteSliderFramerType } from './interfaces';
export const useInfiniteSliderFramer = ({
  slides,
  slideWidthInRem,
  gap,
  speed,
}: UseInfiniteSliderFramerType) => {
  const imagesReducer = useCallback((images: ImageFieldImage[]) => {
    const imagesArr: ImageFieldImage[] = [];

    if (images && images?.length) {
      images.forEach((el: any) => {
        const object = el;
        const key = Object?.keys?.(el)?.[1];
        imagesArr?.push(object?.[key]);
      });

      return imagesArr;
    }

    return [];
  }, []);

  const imagesArr = useMemo(() => {
    if (slides) {
      const duplicatedSlides = [...slides, ...slides];
      return imagesReducer(duplicatedSlides);
    } else {
      return [];
    }
  }, [slides]);

  const duration = useMemo(
    () => (slides?.length ? speed * slides?.length : 1),
    [slides]
  );
  const totalAnimationDistance = useMemo(
    () =>
      slides?.length ? -((slideWidthInRem + gap) * slides?.length * 16) : 0,
    [slides, slideWidthInRem]
  );
  return { imagesArr, duration, totalAnimationDistance };
};
