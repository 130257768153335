import { IHowWeMakeItWorkProps } from './interfaces';
import { InnerWrapper, Section, Subtitle } from './trustedBy.styles';
import InfiniteSliderFramer from 'components/core/InfiniteSliderFramer';
import { ImageFieldImage } from '@prismicio/types';

export const TrustedBy = ({
  companiesTitle,
  companies,
}: IHowWeMakeItWorkProps) => {
  return (
    <Section>
      <InnerWrapper>
        <Subtitle>{companiesTitle}</Subtitle>
        <InfiniteSliderFramer
          slides={companies as ImageFieldImage[]}
          slideWidthInRem={12.25}
        />
      </InnerWrapper>
    </Section>
  );
};
