import styled from '@emotion/styled';
import { defaultContainerSize, mq } from '../../../styles/mixins';
import { css } from '@emotion/react';
import { ParagraphM } from '../../core/Typography/Typography';
import Carousel from 'components/core/Carousel';

export const Section = styled.section`
  ${defaultContainerSize};

  display: flex;
  flex-wrap: wrap;
  z-index: 0;
  position: relative;
  padding-top: 1.813rem;
  padding-bottom: 1.25rem;
  row-gap: 1rem;

  ${mq.medium(css`
    padding-top: 2.625rem;
    row-gap: 2.75rem;
    padding-bottom: 0.813rem;
  `)}
`;

export const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Subtitle = styled.p`
  ${ParagraphM};
  align-self: center;
  color: ${({ theme }) => theme.colors.darker_grey};
  margin: 0rem 0 1rem 0;
  text-align: center;
  width: 100%;

  ${mq.large(css`
    margin-bottom: 1.5rem;
  `)}
`;

export const Company = styled.div`
  position: relative;
  height: 2.625rem;
  width: 6.875rem;

  ${mq.small(css`
    height: 3.5rem;
    width: 9rem;
  `)}

  ${mq.xLarge(css`
    height: 4.625rem;
    width: 12.25rem;
  `)}
`;

export const CarouselWrapper = styled.div`
  display: block;

  width: 12.25rem;
  height: 4.625rem;
  overflow: visible;
`;

export const StyledCarousel = styled(Carousel)`
  width: 12.25rem;
  height: 4.625rem;
  overflow: visible;
`;
