import styled from '@emotion/styled';
import { motion } from 'framer-motion';

type StyledMotionWrapper = {
  $calculatedWidth: number;
};

type StyledImageWrapper = {
  $gap: number;
};

export const StyledContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const StyledMotionWrapper = styled(motion.div, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<StyledMotionWrapper>`
  display: flex;
  width: ${({ $calculatedWidth }) =>
    $calculatedWidth ? `${$calculatedWidth}rem` : '100%'};
`;
export const StyledImageWrapper = styled.div<StyledImageWrapper>`
  margin-right: ${({ $gap }) => ($gap ? `${$gap}rem` : '1rem')};
  align-self: center;
`;
