import Indicator from '../../indicator';
import Image from 'next/image';
import { ImageFieldImage } from '@prismicio/types';
import { imageSerializer } from '../../../utils/imageSerializer';
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.css';
import { CarouselType } from './interfaces';

const imagesReducer = (images: ImageFieldImage[]) => {
  const imagesArr: any = [];

  if (images && images.length) {
    images.forEach((el: any) => {
      const object = el;
      const key = Object.keys(el)[1];
      imagesArr.push(object[key]);
    });

    return imagesArr;
  }

  return;
};

const DEFAULT_CENTER_SLIDE_PERCENTAGE: number = 80;

export const Carousel = ({
  images,
  imgClassName,
  children,
  centerMode,
  onSwipe,
  onChange,
  centerSlidePercentage,
  infinite = true,
}: CarouselType) => {
  const imagesArr = imagesReducer(images);

  return (
    <ReactCarousel
      onChange={onChange}
      onSwipeMove={onSwipe}
      showIndicators={true}
      showArrows={false}
      showStatus={false}
      swipeable
      emulateTouch
      preventMovementUntilSwipeScrollTolerance={true}
      swipeScrollTolerance={50}
      infiniteLoop={infinite}
      showThumbs={false}
      autoPlay={false}
      centerMode={centerMode}
      centerSlidePercentage={
        centerSlidePercentage || DEFAULT_CENTER_SLIDE_PERCENTAGE
      }
      renderIndicator={(onClickHandler, isSelected, index, label) => {
        return (
          <Indicator
            onClickHandler={onClickHandler}
            isSelected={isSelected}
            index={index}
            label={label}
          />
        );
      }}
    >
      {imagesArr?.map((item: any, index: number) => {
        return (
            <Image
              key={index}
              {...imageSerializer(item)}
              className={imgClassName}
            />
        );
      }) || children}
    </ReactCarousel>
  );
};
