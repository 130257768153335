import { InfiniteSliderFramerType } from './interfaces';
import Image from 'next/image';
import { imageSerializer } from 'utils/imageSerializer';
import {
  StyledContainer,
  StyledImageWrapper,
  StyledMotionWrapper,
} from './infiniteSliderFramer.styles';
import { useInfiniteSliderFramer } from './infiniteSliderFramer.hooks';
import { ImageFieldImage } from '@prismicio/types';

export const InfiniteSliderFramer = ({
  slides,
  slideWidthInRem,
  gap = 1.5,
  speed = 4,
}: InfiniteSliderFramerType) => {
  const { imagesArr, duration, totalAnimationDistance } =
    useInfiniteSliderFramer({ slides, slideWidthInRem, gap, speed });

  return (
    <>
      {slides?.length && (
        <StyledContainer>
          <StyledMotionWrapper
            initial={{ x: 0 }}
            animate={{ x: totalAnimationDistance }}
            transition={{
              x: {
                repeat: Infinity,
                duration: duration,
                ease: 'linear',
              },
            }}
            $calculatedWidth={(slideWidthInRem + gap) * imagesArr?.length}
          >
            {imagesArr?.map((slide: ImageFieldImage, index: number) => (
              <StyledImageWrapper key={index} $gap={gap}>
                <Image {...imageSerializer(slide)} />
              </StyledImageWrapper>
            ))}
          </StyledMotionWrapper>
        </StyledContainer>
      )}
    </>
  );
};
