import { IIndicatorProps } from './interfaces';
import { Item, RadioButton } from './indicator.styles';

const Indicator = ({
  onClickHandler,
  isSelected,
  index,
  label,
}: IIndicatorProps) => {
  return (
    <Item onClick={onClickHandler}>
      <RadioButton isSelected={isSelected} />
    </Item>
  );
};

export { Indicator };
