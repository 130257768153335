import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { THEME } from '../../common/consts/theme';

type RadioButtonType = {
  isSelected: boolean;
};

export const Item = styled.li`
  position: relative;
  width: 0.75rem;
  height: 0.75rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.core_white};
  overflow: hidden;
  margin: 0 1.375rem 0 0;
`;

export const RadioButton = styled.div<RadioButtonType>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.core_white};

  ${({ isSelected }) =>
    isSelected &&
    css`
      background-color: ${THEME.colors.primary};
    `};
`;
